<template>
    <div style="height:100%;">
        <a-layout-content style="height:100%;">
            <div class="yj-conten" style="min-height: calc(100% - 48px);background-color:#fff;width:calc(100% - 32px);min-width:max-content">
                <a-card style="width:100%;min-height:100%;" :bordered="false">
                    <a-form-model layout="horizontal" style="padding:10px 00px;height:auto;width:800px;" :rules="rules" ref="ruleForms" :model="ExtensionModel">
                        <a-form-model-item label="文章标题" style="margin-bottom:10px;"
                                           :label-col="formItemLayout.labelCol"
                                           :wrapper-col="formItemLayout.wrapperCol"
                                           prop="Name">
                            <a-input placeholder="请输入标题" v-model="ExtensionModel.Name" onkeyup="this.value=this.value.replace(/\s+/g,'')" :disabled="disabled"></a-input>
                        </a-form-model-item>
                        <a-form-model-item label="文章作者" style="margin-bottom:10px;"
                                           :label-col="formItemLayout.labelCol"
                                           :wrapper-col="formItemLayout.wrapperCol"
                                           prop="Author">
                            <a-input placeholder="请输入作者" v-model="ExtensionModel.Author" onkeyup="this.value=this.value.replace(/\s+/g,'')" :disabled="disabled"></a-input>
                        </a-form-model-item>
                        <a-form-model-item label="文章类型" style="margin-bottom:10px;"
                                           :label-col="formItemLayout.labelCol"
                                           :wrapper-col="formItemLayout.wrapperCol">
                            <a-dropdown :trigger="['click']">
                                <a class="ant-dropdown-link" @click="e => e.preventDefault()" :key="Topic.ID" :disabled="disabled">
                                    {{ Topic.Name }}<a-icon type="down" />
                                </a>
                                <a-menu slot="overlay">
                                    <template v-for="item in MaterialTopicList">
                                        <a-menu-item :key="item.ID">
                                            <a @click="changeTopic(item)">{{ item.Name }}</a>
                                        </a-menu-item>
                                    </template>
                                </a-menu>
                            </a-dropdown>
                        </a-form-model-item>
                        <a-form-model-item label="文章内容" style="margin-bottom:10px;"
                                           :label-col="formItemLayout.labelCol"
                                           :wrapper-col="formItemLayout.wrapperCol"
                                           prop="NewsContent">
                            <Rich v-bind:value.sync="ExtensionModel.NewsContent" rows="800" :disabled="disabled"></Rich>
                        </a-form-model-item>
                        <a-form-model-item label="文章LOGO" style="margin-bottom:20px;"
                                           :label-col="formItemLayout.labelCol"
                                           :wrapper-col="formItemLayout.wrapperCol">
                            <a-upload name="image"
                                      :data="ParamData"
                                      list-type="picture-card"
                                      class="avatar-uploader"
                                      action="/FileServer/Upload"
                                      :fileList="ExtensionModel.fileList"
                                      @change="imgChanges"
                                      :disabled="disabled">
                                <div v-if="ExtensionModel.fileList.length < 1">
                                    <a-icon type="plus" />
                                    <div class="ant-upload-text">上传图片</div>
                                </div>
                            </a-upload>
                        </a-form-model-item>
                        <a-form-model-item label="背景音乐" style="margin-bottom:20px;"
                                           :label-col="formItemLayout.labelCol"
                                           :wrapper-col="formItemLayout.wrapperCol">
                            <a-upload name="music"
                                      :data="ParamDataMusic"
                                      list-type="picture-card"
                                      class="avatar-uploader"
                                      action="/FileServer/Upload"
                                      :fileList="ExtensionModel.MusicfileList"
                                      @change="MusicChanges"
                                      :disabled="disabled">
                                <div v-if="ExtensionModel.MusicfileList.length < 1">
                                    <a-icon type="plus" />
                                    <div class="ant-upload-text">上传音乐</div>
                                </div>
                            </a-upload>
                        </a-form-model-item>
                        <a-form-item class="textal_c">
                            <a-button type="primary" class="margin-r" @click="SubmitExtension('ruleForms')" :disabled="disabled">保存</a-button>
                        </a-form-item>
                    </a-form-model>
                </a-card>
            </div>
        </a-layout-content>
    </div>
</template>
<script type="text/javascript">
    import http from "@/Plugin/Http.js";
    import Rich from "@/components/RichEditor.vue";
    export default {
        name: "MaterialDetail",
        data() {
            return {
                ExtensionModel: {
                    Name: "",
                    Author: "",
                    NewsContent: "",
                    fileList: [],
                    MusicfileList: []
                },
                ParamData: {
                    ProcessorName: "Image",
                    JsonDataParams: ""
                },
                ParamDataMusic: {
                    ProcessorName: "Music",
                    JsonDataParams: ""
                },
                headers: {
                    authorization: 'authorization-text',
                },
                rules: {
                    Name: [{ message: "请输入文章标题", required: true, trigger: "blur" }],
                    Author: [{ message: "请输入文章作者", required: true, trigger: "blur" }],
                    NewsContent: [{ message: "请输入文章内容", required: true, trigger: "blur" }]
                },
                formItemLayout: {
                    labelCol: {
                        xs: { span: 24 },
                        sm: { span: 3 },
                    },
                    wrapperCol: {
                        xs: { span: 24 },
                        sm: { span: 20 },
                    },
                },
                config: {
                    rules: [{ type: 'object', required: true, message: 'Please select time!' }],
                },
                rangeConfig: {
                    rules: [{ type: 'array', required: true, message: 'Please select time!' }],
                },
                Topic: { Name: "请选择类型", ID: 0 },
                ID: 0,
                QueueID: 0,
                disabled: false,
                MaterialTopicList: []
            };

        },
        beforeCreate() {
            this.form = this.$form.createForm(this, { name: 'time_related_controls' });
        },
        components: {
            Rich
        },
        methods: {
            MusicChanges: function (info) {
                var self = this;
                if (info.file.status === 'error') {
                    if (info.file.response && info.file.response.Message) {
                        this.$message.error(info.file.response.Message);
                    }
                    else {
                        this.$message.error(`${info.file.name} 文件上传失败`);
                    }
                    return;
                }
                if (info.file.status === 'done') {
                    info.file.url = info.file.response;
                }
                self.ExtensionModel.MusicfileList = info.fileList;
            },
            imgChanges: function (info) {
                var self = this;
                if (info.file.status === 'error') {
                    if (info.file.response && info.file.response.Message) {
                        this.$message.error(info.file.response.Message);
                    }
                    else {
                        this.$message.error(`${info.file.name} 文件上传失败`);
                    }
                    return;
                }
                if (info.file.status === 'done') {
                    info.file.url = info.file.response;
                }
                self.ExtensionModel.fileList = info.fileList;
            },
            GetMaterialTopic: function () {
                var self = this;
                var op = {
                    url: "/MarketingModule/Material/GetMaterialTopic",
                    data: {
                    },
                    OnSuccess: function (data) {
                        self.MaterialTopicList = data.data;
                        self.loadData();
                    }
                };
                http.Post(op);
            },
            loadData: function () {
                var self = this;
                if (self.QueueID == 0) {
                    var op2 = {
                        url: "/MarketingModule/Material/GetMaterialInfo",
                        data: {
                            id: self.ID
                        },
                        OnSuccess: function (data) {
                            self.ExtensionModel.Author = data.data.Author;
                            if (self.ID != 0) {
                                self.ExtensionModel.Name = data.data.Name;
                                self.Topic = data.data.Topic;
                                self.ExtensionModel.NewsContent = data.data.Content.ContextText;
                                var Logo = data.data.Logo;
                                if (Logo != "" && Logo != null) {
                                    self.ExtensionModel.fileList = [{
                                        name: Logo,
                                        response: Logo,
                                        status: "done",
                                        uid: "0",
                                        url: Logo
                                    }];
                                }
                                else {
                                    self.ExtensionModel.fileList = [];
                                }
                                var Music = data.data.Music;
                                if (Music != "" && Music != null) {
                                    self.ExtensionModel.MusicfileList = [{
                                        name: Music,
                                        response: Music,
                                        status: "done",
                                        uid: "0",
                                        url: Music
                                    }];
                                }
                                else {
                                    self.ExtensionModel.MusicfileList = [];
                                }
                            }
                        }
                    };
                    http.Post(op2);
                } else {
                    var op = {
                        url: "/MarketingModule/Material/GetMaterialQueueInfo",
                        data: {
                            QueueID: self.QueueID
                        },
                        OnSuccess: function (data) {
                            self.ExtensionModel.Author = data.data.Author;
                            self.ExtensionModel.Name = data.data.Name;
                            self.Topic = data.data.Topic;
                            self.ExtensionModel.NewsContent = data.data.Content.ContextText;
                            var Logo = data.data.Logo;
                            if (Logo != "" && Logo != null) {
                                self.ExtensionModel.fileList = [{
                                    name: Logo,
                                    response: Logo,
                                    status: "done",
                                    uid: "0",
                                    url: Logo
                                }];
                            }
                            else {
                                self.ExtensionModel.fileList = [];
                            }
                            var Music = data.data.Music;
                            if (Music != "" && Music != null) {
                                self.ExtensionModel.MusicfileList = [{
                                    name: Music,
                                    response: Music,
                                    status: "done",
                                    uid: "0",
                                    url: Music
                                }];
                            }
                            else {
                                self.ExtensionModel.MusicfileList = [];
                            }
                        }
                    };
                    http.Post(op);
                }
            },
            SubmitExtension(formName) {
                this.$refs[formName].validate(valid => {
                    if (valid) {
                        var self = this;
                        if (self.checkImage() && self.checkTopic()) {
                            var op = {
                                url: "/MarketingModule/Material/EditMaterial",
                                data: {
                                    ID: self.ID,
                                    Name: self.ExtensionModel.Name,
                                    Author: self.ExtensionModel.Author,
                                    Content: self.GetContent(),
                                    MaterialType: 1,
                                    Topic: self.Topic,
                                    TemplateID: 2,
                                    Music: self.ExtensionModel.MusicfileList.length > 0 ? self.ExtensionModel.MusicfileList[0].response : "",
                                    Logo: self.ExtensionModel.fileList.length > 0 ? self.ExtensionModel.fileList[0].response : ""
                                },
                                OnSuccess: function (response) {
                                    console.log(formName + response);
                                    var con = self.ID == "0" ? "新增" : "编辑";
                                    self.$message.success(con + "成功");
                                    self.$router.push({ name: 'NewMaterial_List' });
                                }
                            };
                            http.Post(op);
                        } else {
                            return false;
                        }
                    }
                });
            },
            GetContent: function () {
                var self = this;
                var Content = { ID: self.ID, ContextText: self.ExtensionModel.NewsContent };
                return Content
            },
            checkImage: function () {
                var rt = true;
                var self = this;
                if (self.ExtensionModel.fileList != null) {
                    if (self.ExtensionModel.fileList.length == 0) {
                        rt = false;
                        self.$message.error("请上传LOGO！");
                    }
                }
                return rt;
            },
            checkTopic: function () {
                var rt = true;
                var self = this;
                if (self.Topic.ID == 0) {
                    rt = false;
                    self.$message.error("请选择类型！");
                }
                return rt;
            },
            changeTopic: function (e) {
                var self = this;
                self.Topic = e;
            }
        },
        mounted() {
            this.GetMaterialTopic();
        },
        created: function () {
            this.ID = this.$route.query.id;
            this.QueueID = this.$route.query.QueueID;
            if (this.$route.query.disabled == 1) {
                this.disabled = true;
            }
        },//生命周期，创建完成
    }
</script>